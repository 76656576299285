import React, { useState } from 'react';
import { Button, StyleSheet } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import * as SecureStore from 'expo-secure-store';
import axios from 'axios';
import Colors from '../constants/Colors';
import { ExternalLink } from './ExternalLink';
import { MonoText } from './StyledText';
import { Text, View } from './Themed';
import Constants from 'expo-constants';
import { login } from '../services/apiService';

const { API_URL } = Constants.manifest?.extra ?? {};
const ACCESS_TOKEN = 'ACCESS_TOKEN_KEY';

WebBrowser.maybeCompleteAuthSession();

export default function EditScreenInfo({ path }: { path: string }) {
  const [userEmail, setUserEmail] = useState(null);
  
  const _login = async () => {
    const token = await login();
    if (!token) {
      return;
    }
    await axios.get(API_URL + '/api/v1/me')
    .then(response => {
      setUserEmail(response.data.email);
    }).catch
  }

  const logout = async () => {
    await SecureStore.deleteItemAsync(ACCESS_TOKEN);
    setUserEmail(null);
  }

  return (
    <View>
      { !userEmail &&
        <View style={styles.getStartedContainer}>
          <Text
            style={styles.getStartedText}
            lightColor="rgba(0,0,0,0.8)"
            darkColor="rgba(255,255,255,0.8)">
              Sign in to get started
          </Text>
          <Button title="Sign In" onPress={_login}/>
        </View>
      }
      { userEmail && 
        <View>
          <Text>Logged in as {userEmail}</Text>
          <Button title="Sign Out" onPress={logout}/>
        </View>
      }
    </View>
  );
}

const styles = StyleSheet.create({
  getStartedContainer: {
    alignItems: 'center',
    marginHorizontal: 50,
  },
  homeScreenFilename: {
    marginVertical: 7,
  },
  codeHighlightContainer: {
    borderRadius: 3,
    paddingHorizontal: 4,
  },
  getStartedText: {
    fontSize: 17,
    lineHeight: 24,
    textAlign: 'center',
  },
  helpContainer: {
    marginTop: 15,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  helpLink: {
    paddingVertical: 15,
  },
  helpLinkText: {
    textAlign: 'center',
  },
});
