import axios from 'axios';
import * as SecureStore from 'expo-secure-store';
import { AuthRequest, AuthRequestConfig, DiscoveryDocument, exchangeCodeAsync, makeRedirectUri, ResponseType } from 'expo-auth-session';
import Constants from 'expo-constants';

const { APP_ID, API_URL } = Constants.manifest?.extra ?? {};
const UCRUST_ACCESS_TOKEN = 'ACCESS_TOKEN_KEY';

axios.interceptors.request.use(
  async config => {
    const accessToken = await SecureStore.getItemAsync(UCRUST_ACCESS_TOKEN);
    if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
    return config;
  }
);

const redirectURI = () => makeRedirectUri({ scheme: 'uppercrust-mobile' });
const generateShortUUID = () => { return Math.random().toString(36).substring(2, 15) };

const getAuthRequest = (): AuthRequest => {
  const authRequestOptions: AuthRequestConfig = {
    responseType: ResponseType.Code,
    clientId: APP_ID,
    redirectUri: redirectURI(),
    state: generateShortUUID(),
  }
  return new AuthRequest(authRequestOptions);
};

const discovery:DiscoveryDocument = {
  authorizationEndpoint: API_URL + '/api/v1/oauth/authorize',
  tokenEndpoint: API_URL + '/api/v1/oauth/token',
  revocationEndpoint:  API_URL + '/api/v1/oauth/revoke',
}

const exchangeCode = async (code: string, code_verifier: string) => {
  try {
    const tokenResult = await exchangeCodeAsync(
      {
        code,
        clientId: APP_ID,
        redirectUri: redirectURI(),
        extraParams: { code_verifier: code_verifier || '' },
      },
      discovery
    );
    await SecureStore.setItemAsync(UCRUST_ACCESS_TOKEN, tokenResult.accessToken);
    return tokenResult;
  } catch (e) {}
}

export const login = async () => {
  const authRequest = getAuthRequest();
  const authResponse = await authRequest.promptAsync(discovery);
    if (!!authResponse && authRequest.codeVerifier && authResponse.type === 'success') {
      const codeResponse = await exchangeCode(authResponse.params.code, authRequest.codeVerifier);
      return codeResponse?.accessToken;
    } 
}